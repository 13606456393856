import {Helmet} from "react-helmet";
import Navbar from './navbar'
import Favicon from '../assets/images/favicon.png'


const Header = () => {
  return (
    <>
      <Helmet>
        <title>Mahasos Info Private Ltd</title>
        <meta name="description" content="MahaSOS Be Productive Not Busy" />
        <link rel="icon" href={Favicon} />
      </Helmet>
      <header>
        <Navbar />
      </header>
    </>
  )
  
}

export default Header;