import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../../components/form/contactFrom.css';
import Buttons from '../button';

const ContactForm = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ companyname, setCompanyname ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ message, setMessage ] = useState('');
    
    const onSubmit = (e) => {
        e.preventDefault();
        alert(`Search Submitted ${username} ${email} ${companyname} ${phone} ${message}`)
        console.log(
            username,
            email,
            companyname,
            phone,
            message
        )
    }
    return (
        <form className="contactform" onSubmit={onSubmit}>
            <div className="row">
                <div className={isMobile ? 'w_12' : 'w_6'}>
                    <div className="formgroup">
                        <input 
                            className={username ? 'formcontrol fcActive' : 'formcontrol'}
                            type="text" 
                            name="username" 
                            id="username" 
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required 

                        />
                        <label className="label" htmlFor="name">Name</label>
                    </div>
                </div>
                <div className={isMobile ? 'w_12' : 'w_6'}>
                    <div className="formgroup">
                        <input 
                            id="email" 
                            className={email ? 'formcontrol fcActive' : 'formcontrol'}
                            type="email" 
                            name="email" 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required 

                        />
                        <label className="label" htmlFor="email">E-mail</label>
                    </div>
                </div>
                <div className={isMobile ? 'w_12' : 'w_6'}>
                    <div className="formgroup">
                        <input  
                            className={companyname ? 'formcontrol fcActive' : 'formcontrol'}
                            type="text" 
                            name="companyname" 
                            id="company_name" 
                            value={companyname}
                            onChange={e => setCompanyname(e.target.value)}
                            required 

                        />
                        <label className="label" htmlFor="company">Company Name</label>
                    </div>
                </div>
                <div className={isMobile ? 'w_12' : 'w_6'}>
                    <div className="formgroup">
                        <input  
                            className={phone ? 'formcontrol fcActive' : 'formcontrol'}
                            type="text" 
                            name="contact_number" 
                            id="contact_number" 
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            required 

                        />
                        <label className="label" htmlFor="phone">Contact Number</label>
                    </div>
                </div>
                <div className="w_12">
                    <div className="formgroup">
                        <textarea 
                            className={message ? 'formcontrol fcActive' : 'formcontrol'}
                            type="text" 
                            name="message" 
                            id="message"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            required
                        ></textarea>
                        <label className="label" htmlFor="message">Message</label>
                    </div>
                </div>
                <div className={isMobile ? 'w_12' : 'w_6'}>
                    <Buttons type="submit" title="Submit" />
                </div>
            </div>
        </form>
    );
};

export default ContactForm;