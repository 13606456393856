import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Dropdown from 'react-simple-dropdown';
import { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';


const DropdownMenu = props => {
    const { pathname } = useLocation();
    return (
        <Dropdown className={props.className} {...props}>
            <DropdownTrigger>{props.title}</DropdownTrigger>
            <DropdownContent>
                <ul>
                    {props.options.map(function(d, idx){
                        return (<li {...props} key={idx} className={pathname === d.link ? 'active' : ''}><Link to={d.link}>{d.name}</Link></li>)
                    })}
                </ul>
            </DropdownContent>
        </Dropdown>
    )
}

export default DropdownMenu

DropdownMenu.defaultProps = {
	title: "Dropdown",
    options: [
        {
            name:"Something 1",
            link: "/"
        },
        {
            name:"Something 2",
            link: "/"
        },
        {
            name:"Something 3",
            link: "/"
        },
    ]
};
