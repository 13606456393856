import React from "react"
import './button.css'

const Buttons = props => {
    return (
        <button 
            className={`${props.white ? 'btn-white' : props.secondary ? 'btn-secondary' : ''} btn`} 
            type={props.type}
            onClick={props.onClick}
        >
            {props.title}
        </button>
    )
}

export default Buttons
