import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home'
import About from './pages/about'
import Team from './pages/team'
import Portfolio from './pages/portfolio'
import Services from './pages/services'
import B2BLeadgeneration from './pages/b2bleadgeneration'
import BPO from './pages/bpo'
import Jobs from './pages/jobs'
import Contactus from './pages/contactus'
import InventoryManagement from './pages/ourProduct/inventoryManagement'
import Integrate from './pages/ourProduct/integrate'
import OrderManagement from './pages/ourProduct/orderManagement'
import HireOurTeam from './pages/hireOurTeam'
import ScrollToTop from './components/scrollToTop';
import ArtificialIntelligence from './pages/artificialIntelligence';
import Sixjuly from './pages/sixjuly';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" >
            <Route index={true} element={<Home />} />
            <Route index={false} path="/about" element={<About />} />
            <Route index={false} path="/team" element={<Team />} />
            <Route index={false} path="/portfolio" element={<Portfolio />} />
            <Route index={false} path="/services" element={<Services />} />
            <Route index={false} path="/b2bleadgeneration" element={<B2BLeadgeneration />} />
            <Route index={false} path="/bpo" element={<BPO />} />
            <Route index={false} path="/jobs" element={<Jobs />} />
            <Route index={false} path="/ourProduct/inventoryManagement" element={<InventoryManagement />} />
            <Route index={false} path="/ourProduct/integrate" element={<Integrate />} />
            <Route index={false} path="/ourProduct/orderManagement" element={<OrderManagement />} />
            <Route index={false} path="/artificialIntelligence" element={<ArtificialIntelligence />} />
            <Route index={false} path="/contactus" element={<Contactus />} />
            <Route index={false} path="/hire-our-team" element={<HireOurTeam />} />
            <Route index={false} path="/sixjuly" element={<Sixjuly />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
